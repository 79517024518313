<div class="p-2 pb-4">
  <div class="text-center">
    <div class="row justify-content-center">
      <!-- <h1 class="intro-header mt-2">
        Bend It Ltd
        <img
          class="col-md-1 col-6 pt-5 pt-md-0 bend-logo"
          src="/assets/images/bend-it-logo-navy.svg"
          alt="bend it logo"
        />
      </h1> -->
      <img
        class="col-12 col-md-5 pb-3"
        src="/assets/images/BendIt_Logo_Retro.svg"
        alt="bend it logo"
      />
      <!-- <h2 class="py-3 navy">Curved Headrail and Curtain Track Specialists</h2> -->
    </div>

    <!-- <div class="rounded mx-auto d-block">
      <img class="img-fluid" src="/assets/images/curved-headrail.jpg"
        alt="Suppliers of curved vertical headrails to the trade"
        title="Curved Vertical headrail suppliers to the trade">
    </div> -->

    <div class="row mb-4 justify-content-around">
      <div class="col-md-6 background-l my-2 my-lg-0">
        <div class="card background-panel">
          <div class="card-body my-auto">
            <h2>Curved Headrails</h2>

            <p class="text-white py-2">
              Bend it Ltd specialise in shaping Louvolite's and Benthin vertical
              blind systems for bay (bow) and Arched windows.
            </p>
            <button
              [routerLink]="['/curved-headrails']"
              class="btn c-blue-dark-lt-bk text-white"
            >
              Learn more
            </button>
          </div>
        </div>
      </div>

      <div class="col-md-6 background-r my-2 my-lg-0">
        <div class="card background-panel">
          <div class="card-body my-auto">
            <h2>Curtain Tracks</h2>

            <p class="text-white py-2">
              Bend It Ltd are the UK's leading manufacturers of the Somfy
              Curtain Tracks. Choose from straight, curved & motorised.
            </p>
            <button
              [routerLink]="['/curtain-tracks']"
              class="btn c-blue-dark-lt-bk text-white"
            >
              Learn more
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="row justify-content-around">
      <div class="col-md-4 my-auto">
        <img
          class="img-fluid"
          src="/assets/images/benthin-logo-trident-blinds-wales-chester.png"
          alt="benthin logo"
        />
      </div>
      <div class="col-md-4 my-auto">
        <img
          class="img-fluid"
          src="/assets/images/somfy-logo.jpg"
          alt="somfy logo"
        />
      </div>
      <div class="col-md-4 my-auto">
        <img
          class="img-fluid"
          src="/assets/images/logo-louvolite-white.jpg"
          alt="louvolite logo"
        />
      </div>
    </div>

    <div class="row">
      <div class="col-lg-3 py-2 py-lg-0">
        <a [routerLink]="['/retailers']">
          <div class="card-anim h-100">
            <div class="card h-100 c-blue-dark-dk-bk p-5">
              <h2>My Window needs a Curved Headrail</h2>
              <p class="text-white">
                To find a retailer/distributer of the best curved headrails in
                the business then click here to locate our nearest partner.
              </p>
            </div>
          </div>
        </a>
      </div>

      <div class="col-lg-3 py-2 py-lg-0">
        <a [routerLink]="['/retailers']">
          <div class="card-anim h-100">
            <div class="card h-100 c-blue-dark-dk-bk p-5">
              <h2>My Window needs a Curtain Track</h2>
              <p class="text-white">
                Click here to find one of our trusted curtain track partners
                closest to you.
              </p>
            </div>
          </div>
        </a>
      </div>

      <div class="col-lg-3 py-2 py-lg-0">
        <a [routerLink]="'.'" [fragment]="'ContactForm'">
          <div class="card-anim h-100">
            <div class="card h-100 c-blue-dark-bk p-5">
              <h2 class="icon">I'm a Supplier to the Blind Trade.</h2>
              <p class="text-white description">
                Interested in supplying the best curved blinds in the business
                to your customers? Then click here to get in touch and start the
                process of.
              </p>
            </div>
          </div>
        </a>
      </div>

      <div class="col-lg-3 py-2 py-lg-0">
        <a [routerLink]="'.'" [fragment]="'ContactForm'">
          <div class="card-anim h-100">
            <div class="card h-100 c-blue-dark-bk p-5">
              <h2 class="icon">I'm a Curtain Track supplier</h2>
              <p class="text-white description">
                If you need more information on becoming a supplier of our
                expertly manufactured curtain tracks click here.
              </p>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
</div>
